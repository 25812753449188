// We re-export ThemeProvider from styled-components so that consumers don't
// have to pull it in explicitly if we are the only reason.  (This also helps
// with our style guide!)
import { ThemeProvider } from 'styled-components'

import Cell from './Cell'
import Clue from './Clue'
import DirectionClues from './DirectionClues'
import Crossword from './Crossword'
import { CrosswordContext, CrosswordSizeContext } from './context'

export {
  Cell,
  Clue,
  DirectionClues,
  CrosswordContext,
  CrosswordSizeContext,
  Crossword,
  ThemeProvider,
}

export default Crossword
